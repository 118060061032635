import { hasAnyRole, roles, unitRoles } from './features/access';
import Course from './training/Course';
import Courses from './training/Courses';
import Home from './training/Home';

import Personnel from './admin/Personnel';

import SafetyReport from './training/SafetyReport';
import SafetyReports from './training/SafetyReports';
import { Card } from '@mui/material';
import React, { Suspense } from 'react';

const LazyAdmin = React.lazy(() => import('./admin/Admin'));
const LazyCourseReport = React.lazy(() => import('./training/reports/Course'));

const system =  [
	{
		title: "Home",
		location: "/",
		element: <Home />
	},
	{
		title: "Courses",
		location: "/courses",
		element: <Courses/>,
		roles: [roles.ADMIN, roles.PROGRAM_DIR, roles.PROGRAM_MGR, roles.MED_DIR],
		routes: [{
			location: ":course_id",
			roles: [roles.ADMIN, roles.PROGRAM_DIR, roles.PROGRAM_MGR, roles.MED_DIR],
			element: <Course/>
		},{
			location: ':course_code/report',
			element: <Suspense><LazyCourseReport/></Suspense>
		}
		]
	},
	// {
	// 	title: "Programs",
	// 	location: "/programs",
	// 	element: <Programs/>,
	// 	unitRoles: [unitRoles.ADMIN, unitRoles.COMMAND_MGR, unitRoles.PROGRAM_ADMIN],
	// 	routes: [{
	// 		location: ":code",
	// 		element: <Program/>
	// 	}]		
	// },
	// {
	// 	title: "Personnel",
	// 	location: "/personnel",
	// 	unitRoles: [unitRoles.ADMIN, unitRoles.PERSONNEL_MGR, unitRoles.COMMAND_MGR],
	// 	element: <Personnel/>
	// },
	// {
	// 	title: "Donors",
	// 	location: "/donors",
	// 	unitRoles: [unitRoles.ADMIN, unitRoles.COMMAND_MGR, unitRoles.DONOR_MGR],
	// 	element: <Donors/>,
	// },
	{
		title: "Administration",
		location: "/admin",
		unitRoles: [unitRoles.ADMIN, unitRoles.COMMAND_MGR, unitRoles.PERSONNEL_MGR, unitRoles.BLOOD_DRIVE_COORD, unitRoles.BLOOD_SPECIALIST, unitRoles.DONOR_MGR, unitRoles.MEDICAL_OFFICER, unitRoles.PROGRAM_ADMIN],
		element: <Suspense><LazyAdmin/></Suspense> ,
		requireEmailVerification: false,
		routes: [{
			location: ":unit_id",
			element: <Suspense><LazyAdmin/></Suspense> 
		},
		{
			location: ":unit_id/:tab",
			element: <Suspense><LazyAdmin/></Suspense> 
		},
		{
			location: ":unit_id/:tab/:object_id",
			element: <Suspense><LazyAdmin/></Suspense> 
		}]
	},
	{
		title: "Personnel",
		location: "/personnel",
		adminOnly: true,
		unitRoles: [unitRoles.ADMIN],
		element: <Card><Personnel isActive={true}/></Card>,
		routes: [{
			location: ":user_id",
			element: <Card><Personnel/></Card>
		}]
	},
	{
		title: "Safety Reports",
		location: "/safety_reports",
		roles: [roles.ADMIN, roles.PROGRAM_DIR, roles.PROGRAM_MGR, roles.MED_DIR],
		element: <Card><SafetyReports/></Card>, 
		routes: [{
			location: ":uuid",
			element: <Card><SafetyReport/></Card>
		}]
	},
];

export default system;


